import { apiSlice } from "./apiSlice";

const recordSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      allRecords:builder.query({
        query:(data)=>({
            url:`records/${data}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      
      createRecord:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:`records/${Date.toString()}`
        })
      }),
     
   
    })
})


export const {useAllRecordsQuery,useCreateRecordMutation}=recordSlice