import { ChevronLeftRounded } from '@mui/icons-material'
import { Box, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material';
import { useEditSiteMutation } from '../slices/siteSlice';
import { toast } from 'react-toastify';
import Blog from './blog';
import Page from './page';

export default function Site(props) {
  const site1=props.site
  const changeF=()=>{
setActive('')
  }
  // console.log(site1.isActive)
  
  const [active,setActive]=useState('')
  const[open,setOpen]=useState(false)
const [editSite,{isLoading:isLoadingE}]=useEditSiteMutation()
const handleClose=()=>{
  setOpen(false)
  setSite({})
}
  const [site,setSite]=useState({})



  const editSiteClicked=()=>{
    setSite({
        ...site,isActive:site1.isActive,name:site1.name,header:site1.header,advertisement:site1.advertisement,advertisement_url:site1.advertisement_url,metaTitle:site1.metaTitle,metaDescription:site1.metaDescription,metaKeywords:site1.metaKeywords
    })

    setOpen(true)
  }

// console.log(site)
  const saveSiteClicked=async()=>{

    if(site&&site.name&&site.header&&site.advertisement&&site.advertisement_url&&site.metaTitle&&site.metaDescription&&site.metaKeywords){
  
  
  const formdata=new FormData()
  formdata.append('id',site1._id)

  formdata.append('name',site.name)
  formdata.append('header',site.header)
  formdata.append('advertisement',site.advertisement)
  formdata.append('advertisement_url',site.advertisement_url)
  formdata.append('metaTitle',site.metaTitle)
  formdata.append('metaDescription',site.metaDescription)
  formdata.append('metaKeywords',site.metaKeywords)
  formdata.append('isActive',site.isActive)

  try{
  const site_new=await editSite(formdata).unwrap()
    toast.info(site_new.message)
    handleClose()
  props.changeF()
  
  
  
    }
    catch(error){
        toast.error(error?.data?.message||error.error)
            }
        }
        else{
            toast.error('All fields are necessary')

        }
  }
  
    return (
<div style={{width:'100%'}}>

   {
   


!active?

   <Grid container >
     <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        { <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'#48a3b8'}}>{`Edit ${site1.name} Site`}</DialogTitle>
}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        

        <DialogContent>

<Grid container>
<Grid container style={{padding:'1vh 0px',alignItems:'center'}}>
<Grid item sm={12} xs={12}>
<FormGroup style={{float:'right'}}>
      <FormControlLabel style={{fontWeight:'600'}} control={<Switch onChange={(e)=>setSite({...site,isActive:e.target.checked})}  checked={site.isActive?true:false}color='warning'  style={{color:'#CC0000' }} />} label="Is Active" />
      </FormGroup>
</Grid>

             
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center',paddingRight:'10px'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setSite({...site,header:e.target.files[0]})}  name='file1'/>
           { site.header?<img  src={site.header&&site.header.name?URL.createObjectURL(site.header):site.header}  className='img_comp' />

          :<i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {site.header?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Header Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              
                <TextField focused={true} value={site.name} label='Site Name*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,name:e.target.value})}
         placeholder='Enter site name' />
        
                </Grid> 
            
     
                </Grid> 
         
            </Grid>
            <Grid container style={{padding:'1vh 0px',alignItems:'center'}}>
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center',paddingRight:'10px'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setSite({...site,advertisement:e.target.files[0]})}  name='file2'/>
           { site.advertisement?<img  src={site.advertisement&&site.advertisement.name?URL.createObjectURL(site.advertisement):site.advertisement}  className='img_comp1' />

          :<i  htmlFor='file2'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {site.advertisement?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Advertisement Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              
                <TextField focused={true} value={site.advertisement_url} label='Advertisement url*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,advertisement_url:e.target.value})}
         placeholder='Enter advertisement url*' />
        
                </Grid> 
               
     
                </Grid> 
         
            </Grid>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField focused={true} value={site.metaTitle}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,metaTitle:e.target.value})}
         label='Meta Title*' placeholder='Enter meta title' />
        
                </Grid> 
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField focused={true} value={site.metaDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,metaDescription:e.target.value})}
         label='Meta Description*' placeholder='Enter meta description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField focused={true} value={site.metaKeywords}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,metaKeywords:e.target.value})}
         label='Meta Keywords*' placeholder='Enter meta keywords' />
        
                </Grid> 

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
        <Chip  onClick={()=>saveSiteClicked()}  disabled={isLoadingE}   label={isLoadingE?'Saving':'Save Site'}  className='chip_new'/>
}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>
<Grid container sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>props.changeF()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    <Typography style={{fontSize:20,fontWeight:'700',color:'#48a3b8'}}>
            {site1.name}
        </Typography>
    </Grid>
    <Grid item sm={2} xs={2}>
    <Chip onClick={()=>editSiteClicked()} className='chip_new' label={'Edit site'} />
    </Grid>
 
            </Grid>
            <Grid  style={{padding:'2vh 0px'}}textAlign={'center'} container sm={12}  xs={12}>

         
            <Grid  textAlign={' -webkit-center'} item sm={3} xs={12}>
  <Paper  onClick={()=>setActive('Blogs')} className='ppr_cat'>
  <Typography className='txt_cat'>
  {'Blogs'}
  </Typography>
  
          </Paper>
          </Grid>
          <Grid  textAlign={' -webkit-center'} item sm={3} xs={12}>
  <Paper  onClick={()=>setActive('Pages')} className='ppr_cat'>
  <Typography className='txt_cat'>
  {'Pages'}
  </Typography>
  
          </Paper>
          </Grid>
</Grid>

                
    
         
        </Grid>:active==='Blogs'?<Blog site={site1}  changeF={changeF} />:active==='Pages'?<Page site={site1}  changeF={changeF} />:null}
        </div>
  )
}
