import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import './signin.css'
import { useLocation,useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { setCredentials } from '../slices/authSlice';
// import bcrypt from 'bcrypt';
import {toast} from 'react-toastify';

import  {sha256}  from 'js-sha256'

import {useUserLoginMutation} from'../slices/apiSlice.js' 
import Dashboard from '../dashboard/dashboard.jsx';
// import Dashboard from '../dashboard/dashboard';
export default function Signin() {

  const [active,setActive]=useState('Signin')

  const [data,setData]=useState({})
const dispatch =useDispatch()
const navigate=useNavigate()

const [login,{isLoading:loading}]=useUserLoginMutation()

// console.log(loading)
const {userInfo}=useSelector((state)=>state.auth)

console.log(data.password,data.password?sha256(data.password):'')
// useEffect(() => {

//     if(userInfo){
//      navigate('/')   
//     }

// }, [userInfo,navigate])

const signINClicked=async()=>{

// console.log(sha256(data.password))




    try{
        const res=await login({username:data.username,password:sha256(data.password)}).unwrap()
        dispatch(setCredentials(res.data))
        toast.success(res.message)
        navigate('/')

    }catch(error){
toast.error(error?.data?.message||error.error)
    }

}

  return (
<div>

    {
    userInfo?<Dashboard />:
    
    <div className='bg_signin'>

<Paper style={{minheight:400,background:'var(--blueColor)',width:400,borderRadius:'5%',border:'2px solid #48a3b8'}}>
{
<Grid container  className='signin_cont'>
<Grid item sm={12} xs={12}>
{/* <div style={{height:60,width:60,backgroundColor:'#48a3b8',margin:'1vh 0px'}}>

</div>   */}
        {/* <img  src={require('../images/Icon.png')} onClick={()=>window.location.href='/'}  style={{height:65,backgroundColor:'#48a3b8',margin:'1vh 0px'}} ></img> */}


  </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'#48a3b8',fontWeight:'600',fontSize:19,padding:'1vh 0px'}}>Sign in to continue ..</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'#48a3b8',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>Username</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
value={data.username}
        onChange={(e)=>setData({...data,username:e.target.value})}
        placeholder='Enter username'
        type='username'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'#48a3b8',fontWeight:'500',fontSize:16,padding:'1vh 10px',textAlign:'start'}}>Password</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        // label="TextField"
        placeholder='Enter password'
        onChange={(e)=>setData({...data,password:e.target.value})}
        value={data.password}

        type='password'
        variant="standard"
      />

    </Grid>

    <Grid item sm={12} xs={12}>
   <Button onClick={()=>signINClicked()}  style={{background:'#48a3b8',color:'white',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{loading?'Processing':' Sign in'}</Button>
   {/* <Button onClick={()=>signINClicked()}  style={{background:'#48a3b8',color:'white',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{' Sign in'}</Button> */}

    </Grid>
 
</Grid>
}


</Paper>

    </div>}
    </div>
  )
}
