import React from 'react'
import './PrivacyPolicy.css';
import {Grid} from '@mui/material';
import {useSelector} from 'react-redux';
import Navbar from '../header/navbar';


const Terms_conditions = () => {
   
    const siteVal=useSelector(state=>state.site)

    return (

<>
<Navbar  img={siteVal.site.header} />
        <div className="PP_Block">
        <Grid container xs={12} className="PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid1">
                <h3 style={{color:'#48a3b8'}} className="PrivacyPolicy_Block_MH PrivacyPolicy_Block_H">
             Terms & Conditions 
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
                <p className="PrivacyPolicy_Block_P1 PrivacyPolicy_Block_P">
               
                Welcome to our website {siteVal.site.name}.  By accessing and using this Website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our Website.           </p><br/>

                <h2 style={{color:'#48a3b8'}}>1. Definitions </h2>

                &#x2022; "We", "us", or "our" refers to {siteVal.site.name}  <br /> <br />

                &#x2022; "You" or "your" refers to the user or viewer of our Website. <br /> <br />

                &#x2022; "Clients" refers to the brand whose leads are being captured through this Website.   <br /> <br />


            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
                <h2 style={{color:'#48a3b8'}}>2. Affiliate Relationship  </h2>

                &#x2022; This Website operates as an affiliate of clients. We may earn a commission for referrals made through links on this Website.  <br /> <br />


            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>3. Leads  </h2>

    &#x2022; By submitting your information through this Website, you consent to us collecting and sharing your information with our clients. <br /> <br />

    &#x2022; We do not guarantee the accuracy or completeness of the information provided by users. Users are responsible for ensuring the accuracy of their information.  <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>4. Privacy   </h2>

    &#x2022; Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and disclose information.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>5. Intellectual Property  </h2>

    &#x2022; All content on this Website, including but not limited to text, graphics, logos, images, and software, is the property of clients or its affiliates and is protected by copyright laws.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>6. Limitation of Liability   </h2>

    &#x2022; We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use this Website.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>7. Indemnification   </h2>

    &#x2022; You agree to indemnify and hold us harmless from any claims, damages, losses, or liabilities arising out of your use of this Website or your breach of these terms and conditions.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>8. Changes to Terms and Conditions  </h2>

    &#x2022; We reserve the right to modify these terms and conditions at any time without prior notice. By continuing to use this Website after any such changes, you agree to be bound by the modified terms and conditions.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    
    <h2 style={{color:'#48a3b8'}}>9. Contact Us   </h2>

    &#x2022; If you have any questions about these terms and conditions, please contact us at contact@{siteVal.site.name}.   <br /> <br />


</Grid>
<Grid item lg={12} sm={12} md={12} xs={12} className="PrivacyPolicy_Block_Grid2">
    

By using this Website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.   <br /> <br />


</Grid>
           
       
       </Grid> </div>

       </>

    )
}

export default Terms_conditions
