import {configureStore} from '@reduxjs/toolkit'
import { apiSlice } from './slices/apiSlice'
import authSlice from './slices/authSlice'
import siteAuthSlice from './slices/siteAuthSlice'
const store=configureStore({
    reducer:{
[apiSlice.reducerPath]:apiSlice.reducer,
auth:authSlice,
site:siteAuthSlice

    },
    middleware:(getDefaultMiddleWare)=>getDefaultMiddleWare().concat(apiSlice.middleware),
    devTools:true
})
export default store