import { Box, Chip, CircularProgress, Container, Dialog, DialogContent, Grid,IconButton,Paper,Typography } from '@mui/material'
import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { CSVDownload } from 'react-csv';

import { ChevronLeftRounded } from '@mui/icons-material'
import './dashboard.css'
import { useAllRecordsQuery } from '../slices/recordsSlice';

export default function Records(props) {

    const page1=props.page
 const[openD,setOpenD]=useState(false)

 const [processing,setProcessing]=useState(true)
 const {data:records,isLoading,refetch}=useAllRecordsQuery(page1._id)

 const rows= 

 records?(records.map((item,k) => {
    return  {

      id:k+1,
      'First Name':item.fname,
      'Last Name':item.lname,
      "Email":item.email,
      Phone:item.phone,
      "Created At": (new Date(item.createdAt).getDate().toString().padStart(2,'0')+'/'+(new Date(item.createdAt).getMonth()+1).toString().padStart(2,'0')+'/'+new Date(item.createdAt).getFullYear().toString().padStart(2,'0')),




      



     
    }
}
  )):''


  let headers = records&&records[0]&&rows&&rows[0]?Object.keys(rows[0]):''
  
  let d_data = records&&records[0]&&rows&&rows[0]?rows.map(item=>Object.values(item)):''
 

const columns = [
  { field: 'id', headerName: 'S No.', width: 150 },
  { field: 'First Name', headerName: 'First Name',
  width: 180,  

},

  {
    field: 'Last Name',
    headerName: 'Last Name',
    width: 180,  
   
  },
  {
    field: 'Phone',
    headerName: 'Phone',
  sortable:false,
  filterable:false
  
  },
  
  {
    field: 'Email',
    headerName: 'Email',
    width: 180,  

    // type: 'number',
  }, {
    field: 'Created At',
    headerName: 'Created At',
    // type: 'number',
    width: 180,  

  },
  

  
  

 
];  

const handleCloseD=()=>{
    setOpenD(false)

}
// console.log(columns,  headers,d_data)
const Download=()=>{
    setOpenD(true)
    setTimeout(()=>setProcessing(false),800)
  }
    return (
    <Grid container>


<Dialog
        open={openD}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={()=>handleCloseD()}
        className='Dialog_CButton'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
          style={{float:'right'}}
            aria-label="close"
            onClick={()=>handleCloseD()}
            
            className='icon-close-btn21'>
            <CloseIcon style={{float:'right'}} />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
{!processing?'Downloaded':'Downloading '}          </Typography>
          <Container className="A_Form2_Container">
      <Paper elevation={0} className="A_Form2_Cont_Paper">
          <div className="A_Form2_Div">
  
         {!processing? <div className="Floating_Form2">
          <CSVDownload  target='_self' filename='records' headers={headers} data={d_data}  />
        </div>: <Box sx={{ display: 'flex',justifyContent:'center' }} >

<CircularProgress style={{marginTop:'2vh',height:55,width:55,marginBottom:'2vh',color:'var(--green)'}} />
</Box>}
      </div>
      </Paper>
      </Container>
        </DialogContent>
      </Dialog>
    
 
    <Grid item sm={12} style={{padding:'2vh 0px'}} xs={12}>
       
   {isLoading? <Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',width:'100%',justifyContent:'center' }}>
    <CircularProgress style={{marginTop:'25vh',color:'var(--colorR)'}}  />
      </Box>:
        ( records&&records[0]?<Grid container sm={12}xs={12}> 
    <Grid item style={{padding:'0px 10px',marginBottom:20}} sm={10} xs={12}>
    
    <Box  className='box_transactions' >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={[15]}
                      rowsPerPageOptions={15}
                      checkboxSelection
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                    />
                  </Box> 
                  
         </Grid>    
         <Grid textAlign={'-webkit-center'} item sm={2} xs={12}>

            <Chip  className='chip_new' onClick={()=>Download()}   label='Download' />
</Grid>     
                  </Grid>:<Typography style={{fontWeight:'600',color:'#48a3b8', textAlign:'center',paddingTop:'5vh'}}>No records found</Typography> ) }
            </Grid>
    
    
         
        </Grid>  )
}
