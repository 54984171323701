import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import './thank-you.css'
export default function Thankyou() {
  return (
    <Container>
        <Grid  container style={{minHeight:'90vh',alignContent:'center'}}>
<Grid style={{marginTop:70,height:'100%'}} item sm={12} xs={12}>

<Typography className='mainHead-th'>
Thank you for submitting your details !
</Typography>

<Typography className='subHead-th'>
Our team will contact you soon.
</Typography>

</Grid>

        </Grid>
    </Container>
  )
}
