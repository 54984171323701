import { createSlice } from "@reduxjs/toolkit";
const initialState={
    site:localStorage.getItem('site')?JSON.parse(localStorage.getItem('site')):null
}
const siteAuthSlice =createSlice({
    name:'site',
    initialState,
    reducers:{
        setSiteC:(state,action)=>{
// console.log(action.payload)

            state.site={name:action.payload.name,header:action.payload.header};
            localStorage.setItem('site',JSON.stringify({name:action.payload.name,header:action.payload.header}))
        }
    }
})

export const {setSiteC} =siteAuthSlice.actions
export default siteAuthSlice.reducer