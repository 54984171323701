import { ChevronLeftRounded, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useUploadImageMutation } from '../slices/apiSlice';
import { useAllPagesQuery, useCreatePageMutation } from '../slices/pageSlice';
import Particular_Page from './particularPage';

export default function Page(props) {
  const site1=props.site
  const [page,setPage]=useState({})
  const [uploadImage,{isLoading:isLoadingI}]=useUploadImageMutation()
  const {data,isLoading,refetch} =useAllPagesQuery(site1._id)

  const [active,setActive]=useState('')

const [component,setComponent]=useState({})
  const[open,setOpen]=useState(false)
const [createPage,{isLoading:isLoadingP}]=useCreatePageMutation()
const handleClose=()=>{
  setOpen(false)
  setPage({...page,name:'',header:'',background:'',button:'',urlName:'',components:'',metaTitle:'',metaKeywords:'',metaDescription:'',redirection:'',redirection_active:false})

  setComponent({...component,image:'',title:'',description:''})

}


const changeF=()=>{
setActive('')
refetch()
}

// console.log(page)






const ImageUpload=async(val)=>{
  
      setComponent({
          ...component,image:val
      })
      
      const formData= new FormData()
      formData.append('image',val)
      
      const imageP= await uploadImage(formData).unwrap()
      
      if(imageP){
        setComponent({
          ...component,image:imageP.data
      })
      
      }
  

 
}
  
const addComponent=()=>{
  if(component.image&&component.title&&component.description){

if(page.components&&page.components[0])

{
  
  setPage({...page,components:[...page.components,component]})
} 

else{
  setPage({...page,components:[component]})
}

setComponent({...component,image:'',title:'',description:''})
}
  else{
    toast.error('All fields are necessary')
  }
}


const deleteComp=(val1)=>{

  setPage({...page,components:page.components.filter((val,idx)=>idx!==val1)})

}



const createPageClicked=async()=>{

    if(site1&&page.name&&page.header&&page.background&&page.heading&&page.button&&page.urlName&&page.components&&page.components[0]&&page.metaTitle&&page.metaDescription&&page.metaKeywords){
  
  
  const formdata=new FormData()
  formdata.append('site',site1._id)

  formdata.append('name',page.name)
  formdata.append('header',page.header)
  formdata.append('background',page.background)
  formdata.append('heading',page.heading)
  formdata.append('button',page.button)
  formdata.append('urlName',page.urlName)
  formdata.append('redirection',page.redirection?page.redirection:'')
  formdata.append('redirection_active',page.redirection_active?page.redirection_active:false)


  formdata.append('components',JSON.stringify(page.components))
 

  formdata.append('metaTitle',page.metaTitle)
  formdata.append('metaDescription',page.metaDescription)
  formdata.append('metaKeywords',page.metaKeywords)
  try{
  const page_new=await createPage(formdata).unwrap()
    toast.info(page_new.message)
    handleClose()
  refetch()
  
  
    }
    catch(error){
        toast.error(error?.data?.message||error.error)
            }
        }
        else{
            toast.error('All fields are necessary')

        }
}
    return (
<div style={{width:'100%'}}>

   {
   
   isLoading?
    <Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',width:'100%',justifyContent:'center' }}>
    <CircularProgress style={{marginTop:'25vh',color:'#48a3b8'}}  />
      </Box>
   : 


   (!active?<Grid container >
    <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        { <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'#48a3b8'}}>{`Create Page`}</DialogTitle>
}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        

        <DialogContent>

<Grid container>
<Grid container style={{padding:'1vh 0px',alignItems:'center'}}>
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center',paddingRight:'10px'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setPage({...page,header:e.target.files[0]})}  name='file1'/>
           { page.header?<img  src={page.header&&page.header.name?URL.createObjectURL(page.header):page.header}  className='img_comp' />

          :<i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {page.header?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Header Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              
                <TextField value={page.name} label='Page Name*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,name:e.target.value})}
         placeholder='Enter page name' />
        
                </Grid> 
            
     
                </Grid> 
         
            </Grid>
            <Grid container style={{padding:'1vh 0px',alignItems:'center'}}>
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center',paddingRight:'10px'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setPage({...page,background:e.target.files[0]})}  name='file2'/>
           { page.background?<img  src={page.background&&page.background.name?URL.createObjectURL(page.background):page.background}  className='img_comp1' />

          :<i  htmlFor='file2'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {page.background?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Background Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              
                <TextField value={page.heading} label='Page Heading*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,heading:e.target.value})}
         placeholder='Enter page heading*' />
        
                </Grid> 
               
     
                </Grid> 
         
            </Grid>

            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={page.button}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,button:e.target.value})}
         label='Button Text*' placeholder='Enter button text' />
        
                </Grid> 

                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={page.urlName}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,urlName:e.target.value.replace(' ','-').toLowerCase()})}
         label='Page URL*' placeholder='Enter page url' />
        
                </Grid> 

                <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>

                  <Grid item sm={7} xs={7}>
                <TextField multiline value={page.redirection}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,redirection:e.target.value})}
         label='Redirection URL' placeholder='Enter redirection url' />
        </Grid>
        <Grid item sm={5} xs={5}>
        <FormGroup style={{float:'right'}}>
      <FormControlLabel style={{fontWeight:'600'}} control={<Switch onChange={(e)=>setPage({...page,redirection_active:e.target.checked})}  checked={page&&page.redirection_active?true:false}color='warning'  style={{color:'#CC0000' }} />} label="Redirection active" />
      </FormGroup>
        </Grid>
                </Grid> 
                <div style={{padding:'1.2vh 0px',fontWeight:'600'}}>Bottom Section*</div>      

                <Grid container>


               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>ImageUpload(e.target.files[0])}  name='fileC'/>
           { component.image?<img  src={component.image&&component.image.name?URL.createObjectURL(component.image):component.image}  className='img_comp' />

          :<i  htmlFor='fileC'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {component.image?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Component Image*</h7>
          </div>}
          </div>

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
               
                <TextField value={component.title} label='Title*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setComponent({...component,title:e.target.value})}
        focused={true} placeholder='Enter component title' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={component.description}  style={{width:'100%'}}  variant={'outlined'} 
      focused={true}  onChange={(e)=>setComponent({...component,description:e.target.value})}
         label='Description*' placeholder='Enter component decription' />
        
                </Grid> 
     
                </Grid> 
         
            </Grid>

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
        <Chip   onClick={()=>addComponent()}  disabled={isLoadingI}  label={'Add Component'}  className='chip_new'/>
}
        </div>
<Grid item sm={12} xs={12}>
        {page.components&&page.components[0]?page.components.map((val,idx)=>  <Accordion key={idx}>
         <AccordionSummary

         style={{margin:'1vh 0px'}}
           expandIcon={<ExpandMore />}
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
<Typography style={{width:'80%',textAlign:'left'}}>{val.title}</Typography>
      {<div style={{width:'20%',textAlign:'-webkit-right',paddingRight:'5vw',alignSelf:'center'}}>
      <i style={{color:'black',fontSize:18}}  onClick={()=>deleteComp(idx)} class="fas fa-trash-alt"></i>
          </div>}   
          
            </AccordionSummary>
         <AccordionDetails>
         {/* <Container style={{padding:'2vh 0px'}} > */}
   
  <Grid container style={{padding:'2vh 0px',marginBottom:'2vh',borderBottom:'1px solid black'}} key={idx}>
  <Grid item sm={3} xs={3}>


<img  src={val.image}  className='img_comp' />

   </Grid> 

   <Grid container textAlign={'start'} sm={9} xs={8}>
   <Grid item sm={12} xs={12}>

      <Typography  style={{fontSize:21,fontWeight:'600',color:'#48a3b8'}}>
      {val.title}
       
       </Typography> 
   </Grid> 
   <Grid item sm={12}  xs={12}>
   <Typography style={{fontSize:19,fontWeight:'400'}}>
   {val.description}

       
       </Typography> 


   </Grid> 


   </Grid> 
   {/* <Grid item sm={1} xs={1}>

   <i onClick={()=>EditComp(val,idx)}  style={{color:'#48a3b8',fontSize:20}} class="far fa-edit"></i>

   </Grid>  */}
</Grid>  
           
                {/* </Container> */}
         
         </AccordionDetails>
       </Accordion>):null}

       </Grid>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={page.metaTitle}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,metaTitle:e.target.value})}
         label='Meta Title*' placeholder='Enter meta title' />
        
                </Grid> 
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={page.metaDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,metaDescription:e.target.value})}
         label='Meta Description*' placeholder='Enter meta description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={page.metaKeywords}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setPage({...page,metaKeywords:e.target.value})}
         label='Meta Keywords*' placeholder='Enter meta keywords' />
        
                </Grid> 

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
        <Chip  onClick={()=>createPageClicked()}  disabled={isLoadingP}   label={isLoadingP?'Creating':'Create Page'}  className='chip_new'/>
}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>
    
<Grid container sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>props.changeF()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    <Typography style={{fontSize:20,fontWeight:'700'}}>
            {site1.name} /<span style={{color:'#48a3b8'}}> Pages</span> 
        </Typography>
    </Grid>
    <Grid item sm={2} xs={2}>
    <Chip  onClick={()=>setOpen(true)} className='chip_new' label={'Create new page'} />
    </Grid>
 
            </Grid>
            <Grid  style={{padding:'2vh 0px'}}textAlign={'center'} container sm={12}  xs={12}>

{data&&data[0]?
data.map((val,idx)=>
<Grid key={idx} textAlign={' -webkit-center'} item sm={3} xs={12}>
<Paper  onClick={()=>setActive(val)} className='ppr_cat'>
<Typography className='txt_cat'>
{val.name}
</Typography>

        </Paper>
        </Grid>)
:         <Grid item textAlign={'center'} sm={12} xs={12}>
     <Typography style={{fontWeight:'600',color:'#48a3b8', textAlign:'center',paddingTop:'5vh'}}>No pages found</Typography></Grid>
}</Grid>

                
    
         
        </Grid>:<Particular_Page changeF={changeF} site={site1} page={active} />  )}
        </div>
  )
}
