import { ChevronLeftRounded, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { MyEditorS } from '../editor/editor';
import { useAllBlogsQuery, useCreateBlogMutation, useEditBlogMutation } from '../slices/blogSlice';

export default function Blog(props) {
  const site1=props.site
  
  const {data,isLoading,refetch} =useAllBlogsQuery(site1._id)
  const [active,setActive]=useState('')
  const [editor,setEditor]=useState(true)
  const [blog,setBlog]=useState({})

  const [edit,setEdit]=useState(false)
  const[open,setOpen]=useState(false)

const [createBlog,{isLoading:isLoadingB}]=useCreateBlogMutation()
const [editBlog,{isLoading:loadingE}]=useEditBlogMutation()

const handleClose=()=>{
  setOpen(false)
  setBlog({...blog,id:'',image:'',heading:'',urlName:'',description:'',metaTitle:'',metaDescription:'',metaKeywords:'',views:'',isActive:'',shortDescription:'',createdOn:''})
  setEdit(false)
}

const quillChange=(val)=>{

    // console.log(val)
    setBlog(data => ({ ...data, description:val}))
    
   }





  const createBlogClicked=async()=>{

    if(blog&&blog.heading&&blog.image&&blog.urlName&&blog.metaTitle&&blog.metaDescription&&blog.metaKeywords&&blog.shortDescription&&blog.createdOn){
  
  
  const formdata=new FormData()
  formdata.append('site',site1._id)
  formdata.append('description',blog.description)
  formdata.append('shortDescription',blog.shortDescription)
  formdata.append('createdOn',blog.createdOn)

  formdata.append('heading',blog.heading)
  formdata.append('image',blog.image)
  formdata.append('urlName',blog.urlName)
  formdata.append('metaTitle',blog.metaTitle)
  formdata.append('metaDescription',blog.metaDescription)
  formdata.append('metaKeywords',blog.metaKeywords)
  try{
  const site_new=await createBlog(formdata).unwrap()
    toast.info(site_new.message)
    handleClose()
refetch()  
  
  
    }
    catch(error){
        toast.error(error?.data?.message||error.error)
            }
        }
        else{
            toast.error('All fields are necessary')

        }
  }

const EditClicked=(val)=>{
  setEditor(val.description)
  setBlog({...blog,id:val._id,image:val.image,heading:val.heading,urlName:val.urlName,description:val.description,metaTitle:val.metaTitle,metaDescription:val.metaDescription,metaKeywords:val.metaKeywords,views:val.views,isActive:val.isActive,shortDescription:val.shortDescription,createdOn:val.createdOn})
  setEdit(true)
}

  const SaveClicked=async()=>{
    if(blog&&blog.heading&&blog.image&&blog.urlName&&blog.metaTitle&&blog.metaDescription&&blog.metaKeywords&&blog.views&&blog.shortDescription&&blog.createdOn){
  
  
      const formdata=new FormData()
      formdata.append('id',blog.id)

      formdata.append('site',site1._id)

      formdata.append('description',blog.description)
      formdata.append('shortDescription',blog.shortDescription)
  formdata.append('createdOn',blog.createdOn)

      formdata.append('views',parseInt(blog.views))
      formdata.append('isActive',blog.isActive)

      formdata.append('heading',blog.heading)
      formdata.append('image',blog.image)
      formdata.append('urlName',blog.urlName)
      formdata.append('metaTitle',blog.metaTitle)
      formdata.append('metaDescription',blog.metaDescription)
      formdata.append('metaKeywords',blog.metaKeywords)
      try{
      const site_new=await editBlog(formdata).unwrap()
if(site_new){
  toast.info(site_new.message)
  refetch()  

  handleClose()
}

       
      
      
        }
        catch(error){
            toast.error(error?.data?.message||error.error)
                }
            }
            else{
                toast.error('All fields are necessary')
    
            }


  }


  
    return (
<div style={{width:'100%'}}>

   {
   
   isLoading?
    <Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',width:'100%',justifyContent:'center' }}>
    <CircularProgress style={{marginTop:'25vh',color:'#48a3b8'}}  />
      </Box>
   : 


   <Grid container >
     <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        { <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'#48a3b8'}}>{`Create new blog`}</DialogTitle>
}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        

        <DialogContent>

<Grid container>

<Grid item  style={{padding:'1vh 0px',alignItems:'center'}}sm={12} xs={12}>
              
              <div style={{textAlign:'center'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setBlog({...blog,image:e.target.files[0]})}  name='file1'/>
           {blog.image?<img  src={blog.image&&blog.image.name?URL.createObjectURL(blog.image):blog.image}  className='img_game' />

          :<i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {blog.image?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Blog Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

               
         
        
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.heading}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,heading:e.target.value})}
         label='Blog Heading*' multiline placeholder='Enter blog heading' />
        
                </Grid> 


                <Grid container  sm={12} xs={12}>


                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.urlName}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,urlName:e.target.value.replace(' ','-').toLowerCase()})}
         label='Blog Url*' placeholder='Enter blog url' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.metaTitle}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,metaTitle:e.target.value})}
         label='Meta Title*' placeholder='Enter meta title' />
        
                </Grid> 
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.metaDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,metaDescription:e.target.value})}
         label='Meta Description*' multiline placeholder='Enter meta description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.metaKeywords}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,metaKeywords:e.target.value})}
         label='Meta Keywords*'  multiline placeholder='Enter meta keywords' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.shortDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,shortDescription:e.target.value})}
         label='Short Description*'  multiline placeholder='Enter short description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.createdOn}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,createdOn:e.target.value})}
         label='Created On(DD-MM-YYYY)*'   placeholder='Enter creation date' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                   <div style={{paddingBottom:5,fontWeight:'600'}}>Blog Description*</div>      
                         
                <MyEditorS  quillChange={quillChange} editor={editor}/>
       
                            </Grid> 
               
               
               
               
               
               
                </Grid> 

           

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
        <Chip   onClick={()=>createBlogClicked()} disabled={isLoadingB}   label={isLoadingB?'Creating': 'Create Blog'}  className='chip_new'/>
}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>
<Grid container sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>props.changeF()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    <Typography style={{fontSize:20,fontWeight:'700'}}>
            {site1.name} /<span style={{color:'#48a3b8'}}> Blogs</span> 
        </Typography>
    </Grid>
    <Grid item sm={2} xs={2}>
    <Chip disabled={edit} onClick={()=>setOpen(true)} className='chip_new' label={'Create new blog'} />
    </Grid>
 
            </Grid>

            <Container>
          {edit? 
          
          <Grid style={{padding:'1vh 0px'}} container>
                       
          
                          <Grid container sm={12} xs={12}>
                        

                          <Grid item  style={{padding:'1vh 0px',alignItems:'center'}}sm={12} xs={12}>
              
              <div style={{textAlign:'center'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setBlog({...blog,image:e.target.files[0]})}  name='file1'/>
           {blog.image?<img  src={blog.image&&blog.image.name?URL.createObjectURL(blog.image):blog.image}  className='img_game' />

          :<i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {blog.image?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Blog Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
           {<FormGroup style={{float:'right'}}>
                <FormControlLabel style={{fontWeight:'600'}} control={<Switch onChange={(e)=>setBlog({...blog,isActive:e.target.checked})}  checked={blog.isActive?true:false}  color='warning'  style={{color:'#CC0000' }} />} label="Is Active" />
                </FormGroup>}
                          
                  
                          </Grid> 
         
        
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.heading}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,heading:e.target.value})}
         label='Blog Heading*' multiline placeholder='Enter blog heading' />
        
                </Grid> 


                <Grid container  sm={12} xs={12}>


                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.urlName}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,urlName:e.target.value.replace(' ','-').toLowerCase()})}
         label='Blog Url*' placeholder='Enter blog url' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField     value={blog.views}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,views:e.target.value})}
         label='Blog Views*' placeholder='Enter blog views' />
        
                </Grid>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.metaTitle}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,metaTitle:e.target.value})}
         label='Meta Title*' placeholder='Enter meta title' />
        
                </Grid> 
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.metaDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,metaDescription:e.target.value})}
         label='Meta Description*' multiline placeholder='Enter meta description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.metaKeywords}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,metaKeywords:e.target.value})}
         label='Meta Keywords*'  multiline placeholder='Enter meta keywords' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.shortDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,shortDescription:e.target.value})}
         label='Short Description*'  multiline placeholder='Enter short description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField  value={blog.createdOn}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setBlog({...blog,createdOn:e.target.value})}
         label='Created On(DD-MM-YYYY)*'   placeholder='Enter creation date' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                   <div style={{paddingBottom:5,fontWeight:'600'}}>Blog Description*</div>      
                         
                <MyEditorS  quillChange={quillChange} editor={editor}/>
       
                            </Grid> 
               
               
               
               
               
               
                </Grid> 

           


                          
          
          
          
                          </Grid> 
          
                   
          
                  <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
                  {
                  
                  <div>
                  <Chip  onClick={()=>SaveClicked()} label={loadingE?'Saving':'Save Blog'}  className='chip_new'/>
                  <Chip  onClick={()=>handleClose()} label={'Cancel'} style={{marginLeft:20,backgroundColor:'black',color:'white'}}  />
          
                  </div>
          
          }
                  </div>
                  </Grid>
          
          
          
          
          
          : <Grid  style={{padding:'2vh 10px',textAlign:'start'}}textAlign={'center'} container sm={12}  xs={12}>

         

         {
          data&&data[0]?(data.map((val,idx)=>
         <Accordion key={idx}>
         <AccordionSummary
           expandIcon={<ExpandMore />}
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
<Typography style={{width:'80%',textAlign:'left'}}>{val.heading}</Typography>
      {val.isActive?<div style={{width:'20%',textAlign:'-webkit-right',paddingRight:'5vw',alignSelf:'center'}}>
      <i class="fas fa-check-circle completed"></i>
          </div>:null}         </AccordionSummary>
         <AccordionDetails>
             <Grid container>
              
 
                 <Grid container sm={11} xs={11}>
                 <Grid textAlign={'center'} item  sm={12} xs={12}>
 
                 <img  src={val.image}  className='img_game1' />
                 </Grid>
 
                 <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
 
                    <Typography  style={{fontSize:21,fontWeight:'600',color:' #48a3b8'}}>
                    {val.heading}
                     
                     </Typography> 
                 </Grid> 
                 {/* <Grid item sm={12} xs={12}>
                 <Typography style={{fontSize:19,fontWeight:'400'}}>
                 {val.url}
 
                     
                     </Typography> 
                 </Grid>  */}
                 <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
 
                 <Typography style={{marginBlock:0,marginInline:0}} className='blog_description'   dangerouslySetInnerHTML={{__html:val.description}}  >
                     
                     </Typography> 
                 </Grid> 
                 <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Blog URL</b> : {val.urlName}
 
                     
                     </Typography> 
                 </Grid> 

                 <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Short Description</b> : {val.shortDescription}
 
                     
                     </Typography> 
                 </Grid> <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Created on</b> : {val.createdOn}
 
                     
                     </Typography> 
                 </Grid> 
                 <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Blog Views</b> : {val.views}
 
                     
                     </Typography> 
                 </Grid> 
                 <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Meta Title</b> : {val.metaTitle}
 
                     
                     </Typography> 
                 </Grid> 
                   <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Meta Description</b> : {val.metaDescription}
 
                     
                     </Typography> 
                 </Grid>
                    <Grid style={{padding:'1vh 0px'}} item sm={12} xs={12}>
                 <Typography  style={{fontSize:16,fontWeight:'400'}} color={'text.secondary'}>
                  <b>Meta Keywords</b> : {val.metaKeywords}
 
                     
                     </Typography> 
                 </Grid> 
 
                 </Grid> 
                 <Grid item sm={1} xs={1}>
 
                 <i onClick={()=>EditClicked(val)}  style={{color:' #48a3b8',fontSize:20}} class="far fa-edit"></i>
 
                 </Grid> 
             </Grid>
         
         </AccordionDetails>
       </Accordion> )):
                   <Grid  style={{padding:'2vh 0px'}}textAlign={'center'} item sm={12}  xs={12}>

       <Typography style={{fontWeight:'600',color:'#48a3b8',paddingTop:'5vh'}}>No blogs found</Typography>
       </Grid>}
</Grid>
}
</Container>

                
    
         
        </Grid>}
        </div>
  )
}
