import { Site } from "../constants";
import { apiSlice } from "./apiSlice";

const pageSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      allPages:builder.query({
        query:(id)=>({
            url:`page/${id}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      particularPage:builder.query({
        query:(id)=>({
            url:`page-p/${id}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      particularPageURL:builder.query({
        query:(id)=>({
            url:`page-url/${id}?q=${Site}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      createPage:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:`page/${data.get('site')}`
        })
      }),
      editPage:builder.mutation({
        query:(data)=>({
            url:`page/${data.id}`,
            method:'PUT',
            body:data
        })
    }),
   
    })
})


export const {useAllPagesQuery,useParticularPageQuery,useParticularPageURLQuery,useCreatePageMutation,useEditPageMutation}=pageSlice