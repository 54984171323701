import { Container, Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';import React from "react";
import './blogsMain.css'
import CircularProgress from '@mui/material/CircularProgress';

import { Helmet } from "react-helmet";
import D6_Home_SCarousel from "./Home_MainCrousel/home_crousel";
import { useAllBlogsQuery } from "../slices/blogSlice";
import { Site } from "../constants";
import Navbar from "../header/navbar";


export default function BlogsMain(props) {

const {data,isLoading,refetch}=  useAllBlogsQuery(Site)



  
if(isLoading){
  return(
  <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
  <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
  </Grid>)
}


  else if(!isLoading&&data&&data[0]){

    
  return (
    
      <div>

        <Navbar img={data[0].site.header}/>
        <Helmet>



        {/* <!-- HTML Meta Tags --> */}
    
    <title>{data[0].site.metaTitle}</title>
       
    <meta
          name="description"
          content={data[0].site.metaDescription}
        />

<meta name="keywords" content={data[0].site.metaKeywords} />

        {/* <!-- Google / Search Engine Tags --> */}
       
       
        <meta itemprop="name" content={data[0].site.metaTitle}
         />
        
    
   
    <meta
    itemprop="description"
    content={data[0].site.metaDescription}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content={`https://${data[0].site.name}/`} />
  
        <meta property="og:type" content="website" />
      
        <meta property="og:title" content={data[0].site.metaTitle} />
        
    
    <meta
          property="og:description"
          content={data[0].site.metaDescription}
        />
        
      
      

      
       
      </Helmet>
     
    <Container style={{maxWidth:1280,marginTop:'20vh'}}>

    <D6_Home_SCarousel data={data}/>

  
   
      <Grid container style={{paddingTop:30,paddingBottom:30}}> 
     
        <Grid  container className="grid_blog" style={{cursor:'pointer'}} sm={8} xs={12}>
      
          {data.filter((val,idx)=>val.isActive===true).sort((a,b)=>a.views<b.views?1:-1).map((val1,idx)=>{
  return(
    <Grid onClick={()=>window.location.href=`/blog/${val1.urlName}`}  key={idx} container sm={6} xs={12} style={{marginBottom:40}}>
          <Grid item style={{textAlign:'-webkit-center'}} sm={12} xs={12}>
          <Card sx={{ maxWidth: 345 }}>
<CardActionArea>
<CardMedia
component="img"
height="220"
image={val1.image}
alt={val1.urlName}
/>

</CardActionArea>
</Card>
          </Grid>
          <Grid className="value_grid" item sm={12} xs={12}>
          <Grid item sm={12} xs={12}>
            <Typography className="heading_Q">{val1.heading}</Typography>
            
            </Grid>
            <Grid container style={{paddingTop:20}} sm={12} xs={12}>
              {/* <Grid container style={{alignItems:'center'}} sm={12} xs={12}> 
              <i class="far fa-user"></i>
            <Typography style={{paddingLeft:5,paddingBottom:5}} >{val1.createdBy}</Typography> 
              </Grid> */}
              <Grid  container style={{alignItems:'center'}} sm={6} xs={6}> 
              <i class="far fa-clock"></i>
            <Typography style={{paddingLeft:5}} >{val1.createdOn} </Typography> 
       </Grid>
              <Grid container style={{alignItems:'center'}}  sm={6} xs={6}>
              <i class="fas fa-eye"></i>
            <Typography style={{paddingLeft:5}} >{val1.views} </Typography> 
  </Grid>
            
            </Grid>
            <Grid  item style={{alignItems:'center'}} sm={12} xs={12}>
              <Typography className="heading_A">{val1.shortDescription}</Typography>
            </Grid>

            <Grid  container style={{alignItems:'center',justifyContent:'end',paddingTop:20,paddingRight:20}} sm={12} xs={12}>
              <Typography  className="read_more">Read more</Typography>
              <i class="fas fa-angle-double-right"></i>
            </Grid>

         
          
          </Grid>
          </Grid>
  )
  })} 
        </Grid>

        <Grid className="small_grid" container sm={4} xs={12}>
          <Grid container  >
     
          <Grid item sm={12} xs={12}>

            <Card onClick={()=>window.open(data[0].site.advertisement_url,'_blank')} sx={{ maxWidth: '100%' }} >
            {/* <Card onClick={()=>window.location.replace(val1.url,'_blank')} sx={{ maxWidth: '100%' }} >   */}
<CardActionArea>
<CardMedia
component="img"
height="250"
image={data[0].site.advertisement}
alt="green iguana"
/>

</CardActionArea>
</Card>  
            </Grid>
             
           
        <Grid container style={{justifyContent:'center',alignItems:'center'}} sm={12} xs={12}>
        <Typography className="most_popular">MOST POPULAR</Typography>
        <i class="fas fa-bell"></i>
        </Grid>
        {data.filter((val,idx)=>(val.isActive===true&&idx<6)).sort((a,b)=>a.views<b.views?1:-1).map((val1,idx)=>{
  return(
        <Grid  key={idx} onClick={()=>window.location.href=`/blog/${val1.urlName}`}  container style={{marginBottom:30,cursor:'pointer'}} className='small_gridM'  sm={12} xs={12}>
        <Grid item style={{textAlign:'-webkit-center'}} sm={5} xs={12}>
          <Card sx={{ maxWidth: 205 }}>
<CardActionArea>
<CardMedia
component="img"
height="120"
image={val1.image}
alt="green iguana"
/>

</CardActionArea>
</Card>
          </Grid>
          <Grid item style={{textAlign:'center'}} sm={7} xs={12}>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularT">
   {val1.heading}
   </Typography>
 </Grid>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularV">
   <b>Views:</b>{val1.views}
   </Typography>
 </Grid>
          </Grid>
        </Grid>)
  })} 
       
        </Grid>
        
        </Grid>
      </Grid>
    </Container>
    {/* <MyEditorS /> */}
    </div>)}
    else{
      return(
        <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
<img  src={require('../images/wip.gif')} style={{height:'95vh' ,width:'100%',objectFit:'contain'}} />
        </Grid>)
    }
}
