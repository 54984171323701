import React from "react";

import ReactQuill from 'react-quill';
// import { useQuill } from "react-quilljs";

// import "quill/dist/quill.snow.css"; // Add css for snow theme
import { Container } from "@mui/material";
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

export function MyEditorS(props)  {
  const {editor}=props


  // console.log(editor)
  const placeholder = 'Start writing description here...';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
  
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],
  
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link','image'],
      [{ color: [] }, { background: [] }],
      
  
      ['clean'],
    ],
  };
  
  return (
   
    <Container style={{padding:2}}>
    <div style={{ width:'auto', height: 300 ,marginBottom:20,paddingBottom:50}}>

    <ReactQuill
    style={{height:300}}
        // value={editor}
        defaultValue={editor}
        onChange={(val)=> props.quillChange(val)}
        // formats={this.formats}
        modules={modules}
        placeholder={placeholder}
      />
    </div>
    </Container>
  );
};