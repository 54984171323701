
import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
// https://fms-leadgen-6e30a2bc28ae.herokuapp.com/api/
const baseQuery=fetchBaseQuery({baseUrl:'https://fms-leadgen-6e30a2bc28ae.herokuapp.com/api/'})

export const apiSlice=createApi({
    baseQuery,
    // tagTypes:[],
    endpoints:(builder=>({

        userLogin:builder.mutation({
            query:(data)=>({
                url:`auth`,
                method:'POST',
                body:data
            })
        }),
        uploadImage:builder.mutation(
            

            {
    
    
            query:(data)=>(
                {
    
    
                url:`upload`,
                method:'POST',
                body:data,
    
    
    
            }),
            
    
        }
        
        )
    }))
})
 export const {useUserLoginMutation,useUploadImageMutation}=apiSlice