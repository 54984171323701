import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import Navbar from './header/navbar';
import Signin from './Signin/signin';
import Homepage from './homepage/homepage';
import BlogsMain from './blogs/blogsMain';
import BlogsSub from './blogs/blogsSub';
import Page from './Pages/page';
import AllRoutes from './AllRoutes';
import ScrollT from './STTP/scroll_Top';

function App() {


  return (
  
  <BrowserRouter>
    {/* <Navbar /> */}
  
<AllRoutes />
    <ToastContainer />
    <ScrollT />
    </BrowserRouter>
  );
}

export default App;
