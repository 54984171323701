import React from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './header/navbar';
import Signin from './Signin/signin';
import Homepage from './homepage/homepage';
import BlogsMain from './blogs/blogsMain';
import BlogsSub from './blogs/blogsSub';
import Page from './Pages/page';
import Thankyou from './thank-you/thank-you';
import Privacy_policy from './privacypolicy/Privacy&policy';
import Terms_conditions from './privacypolicy/Terms&Conditions';

export default function AllRoutes() {
 const {pathname}=useLocation()
//  console.log(pathname)
    return (
    <>
    
    
    <Routes>
      
{pathname==='/'? <Route path='/' element={<BlogsMain />} />:    <Route path='/:id' element={<Page />} />
}    <Route path='/blog/:id' element={<BlogsSub />} />
<Route  path='/thank-you' element={<Thankyou />} />

    <Route path='/fms-gate' element={<Signin />} />
    <Route  path='/privacy-policy' element={<Privacy_policy />} />
    <Route  path='/terms-and-conditions' element={<Terms_conditions />} />

    </Routes>
    
    </>
  )
}
