import { Container, Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography } from '@mui/material';
import React, { useState ,useEffect} from "react";
import './blogsSub.css'
import './blogsMain.css'

import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Helmet } from "react-helmet";
import { useAllBlogsQuery, useParticularBlogQuery } from "../slices/blogSlice";
import { Site } from "../constants";
import Navbar from "../header/navbar";
export default function BlogsSub(props) {


  const {id}=useParams()

  const {data,isLoading,refetch}=useParticularBlogQuery(id)

 const {data:blogsData,isLoading:isLoadingB}=useAllBlogsQuery(Site)
// console.log(urlParams.get('fmsID'))
 
if(isLoading||isLoadingB){
  return(
    <Grid sm={12} style={{textAlign:'center'}} xs={12} item>
        <CircularProgress style={{justifyContent:'center',height:60,width:60,marginTop:'40vh',marginBottom:'10vh'}} />
        </Grid>)
}


 else if(data&&!isLoading){
 
  return (
      <div> 

<Navbar img={data.site.header}/>

    <Container style={{maxWidth:1440}} >
    <Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>{data.metaTitle}</title>
       
    <meta
          name="description"
          content={data.metaDescription}
        />

<meta name="keywords" content={data.metaKeywords} />

        {/* <!-- Google / Search Engine Tags --> */}
       
       
        <meta itemprop="name" content={data.metaTitle}
         />
        
    
   
    <meta
    itemprop="description"
    content={data.metaDescription}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content={`https://${data.site.name}/blog/${data.urlName}`} />
  
        <meta property="og:type" content="website" />
      
        <meta property="og:title" content={data.metaTitle} />
        
    
    <meta
          property="og:description"
          content={data.metaDescription}
        />
        
      
      

      
       
      </Helmet>
      <Grid container style={{paddingTop:'20vh',paddingBottom:30}}>
        <Grid container className="grid_blog" sm={8} xs={12}>
          <Grid container style={{marginBottom:40}}>
 
          <Grid className="blogSub_grid" item sm={12} xs={12}>
          <Grid item sm={12} xs={12}>
            <Typography className="heading_Sub">{data.heading}</Typography>
            
            </Grid>
            <Grid container style={{paddingTop:20}} sm={12} xs={12}>
              {/* <Grid container style={{alignItems:'center',paddingBottom:30}} sm={5} xs={12}> 
              <i class="far fa-user"></i>
            <Typography style={{paddingLeft:5}} className="subSection" >{data.createdBy}</Typography> 
              </Grid> */}
              <Grid  container style={{alignItems:'center',paddingBottom:30}} sm={4} xs={6}> 
              <i class="far fa-clock"></i>
            <Typography style={{paddingLeft:5}} className="subSection" >{data.createdOn}</Typography> 
       </Grid>
              <Grid container style={{alignItems:'center',paddingBottom:30}}  sm={3} xs={6}>
              <i class="fas fa-eye"></i>
            <Typography style={{paddingLeft:5}} className="subSection" >{data.views} </Typography> 
  </Grid>
            
            </Grid>
            <Grid>
               <img src={data.image} className="img_sub" />
            </Grid>
            {data.description?<Grid  item style={{alignItems:'center'}} sm={12} xs={12}>
              <Typography dangerouslySetInnerHTML={{__html:data.description}} className="heading_A_Sub">
</Typography>
            </Grid>:null}
            
           

          

         
          
          </Grid>
          </Grid>
        
        </Grid>

        <Grid className="small_grid" container sm={4} xs={12}>

          <Grid container >
   
          <Grid item sm={12} xs={12}>
  
            <Card onClick={()=>window.open(data.site.advertisement_url,'_blank')}  sx={{ maxWidth: '100%' }} >
<CardActionArea>
<CardMedia
component="img"
height="250"
image={data.site.advertisement}
alt="green iguana"
/>

</CardActionArea>
</Card> 
            </Grid>
            
          
        <Grid container style={{justifyContent:'center',alignItems:'center'}} sm={12} xs={12}>
        <Typography className="most_popular">MOST POPULAR</Typography>
        <i class="fas fa-bell"></i>
        </Grid>
        {blogsData.filter((val,idx)=>(val.isActive===true&&idx<6&&val._id!==data._id)).sort((a,b)=>a.views<b.views?1:-1).map((val1,idx)=>{
  return(
        <Grid key={idx}  onClick={()=>window.location.href=`/${val1.urlName}`} container style={{marginBottom:30,cursor:'pointer'}} className='small_gridM'  sm={12} xs={12}>
        <Grid style={{textAlign:'-webkit-center'}} item sm={5} xs={12}>
          <Card sx={{ maxWidth: 205 }} >
<CardActionArea>
<CardMedia
component="img"
height="120"
image={val1.image}
alt="green iguana"
/>

</CardActionArea>
</Card>
          </Grid>
          <Grid item style={{textAlign:'center'}} sm={7} xs={12}>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularT">
   {val1.heading}
   </Typography>
 </Grid>
 <Grid item sm={12} xs={12}>
   <Typography className="most_popularV">
   <b>Views:</b>{val1.views}
   </Typography>
 </Grid>
          </Grid>
        </Grid>
      )
    })} 
        </Grid>
        
        </Grid>
      </Grid>
    </Container> 
    </div>
  )
}
else{
 window.location.href='/'
}}
