import { apiSlice } from "./apiSlice";

const siteSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      allSites:builder.query({
        query:()=>({
            url:'site',
            
        }),
        keepUnusedDataFor:5
      })  ,
      createSite:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:'site'
        })
      }),
      editSite:builder.mutation({
        query:(data)=>({
            method:'PUT',
            body:data,
            url:'site'
        })
      })
    })
})


export const {useAllSitesQuery,useCreateSiteMutation,useEditSiteMutation}=siteSlice