import { apiSlice } from "./apiSlice";

const blogSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
      allBlogs:builder.query({
        query:(id)=>({
            url:`blog/${id}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      particularBlog:builder.query({
        query:(id)=>({
            url:`blog-p/${id}`,
            
        }),
        keepUnusedDataFor:5
      })  ,
      createBlog:builder.mutation({
        query:(data)=>({
            method:'POST',
            body:data,
            url:`blog/${data.get('site')}`
        })
      }),
      editBlog:builder.mutation({
        query:(data)=>({
            method:'PUT',
            body:data,
            url:`blog/${data.get('id')}`
        })
      }),
   
    })
})


export const {useAllBlogsQuery,useParticularBlogQuery,useCreateBlogMutation,useEditBlogMutation}=blogSlice