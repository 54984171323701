import React, { useState } from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import { useSelector } from 'react-redux';
// import { useLogoutUserMutation } from '../../../slices/usersApiSlice';

function Navbar(props) {


  const img=props.img
  const [navbarA,setNavbar] = useState(false);
  const {userInfo}=useSelector((state)=>state.auth)
// const [logoutUser,{isLoading}]=useLogoutUserMutation()
  const changeBackground = () => {
    if(window.scrollY >= 100)
    {
        setNavbar(true);
    }
    else {
        setNavbar(false);
    }
};
window.addEventListener('scroll',changeBackground);
      
const imgClick=()=>{
  if(img){

  }
  else{
    window.location.href='/'
  }
}
const signoutPressed=async()=>{

  // const res =await logoutUser().unwrap()

    localStorage.removeItem('userInfo')
    window.location.reload()
  

}
return(
            <nav>
            <div style={{width:'100%',justifyItems:'center',display:'grid'}}>
            <div className={'Navbar_Items'}>

<Grid  display={'flex'} alignItems={'center'} container style={{paddingRight:'4rem'}}  className='gird_nav'>

<Grid item sm={3} xs={3}>
{/* <div style={{height:50,width:50,borderRadius:'50%',backgroundColor:'white',marginLeft:'2vw'}}> */}
<img  src={img?img:require('../images/logo.svg').default} onClick={()=>imgClick()}  style={{height:60,marginLeft:'2vw'}} ></img>

{/* </div> */}
</Grid>
<Grid container justifyContent={'flex-end'} sm={9} xs={9}>



{userInfo&&window.location.pathname==='/fms-gate'?<Grid item  className='Navbar_Link1' onClick={()=>signoutPressed()} style={{color:'white'}}>
SIGNOUT                
</Grid>:null}
    </Grid>   
    </Grid>               
            </div>


            </div>





            </nav>
        );
    }
    
    export default Navbar;